import React, { useState, useEffect } from 'react'
import { ImSpinner4, ImCross } from 'react-icons/im'
import axios from 'axios'

const Stats = () => {
    const logo = 'https://www.legendsqueue.com/Images/logo2.png';

    const [data, setData] = useState([])

    const [showLoader, setLoader] = useState(true)
    const [showBody, setBody] = useState(false)

    const [Total, setTotal] = useState(0)
    const [HappyAtCurrentPosition, setHappyAtCurrentPosition] = useState(0)
    const [PositionResponsibilities, setPositionResponsibilities] = useState(0)
    const [PaidFairly, setPaidFairly] = useState(0)
    const [HappyAtEmployer, setHappyAtEmployer] = useState(0)
    const [LookingForWork, setLookingForWork] = useState(0)
    const [RecommendEmployer, setRecommendEmployer] = useState(0)
    const [ValuedByEmployer, setValuedByEmployer] = useState(0)

    useEffect(() => {
        axios
            .get("https://api.legendsqueue.com/api/employee/stats")
            .then((res) => {
                setLoader(false);
                setBody(true);
                setData(res.data)

                setTotal(res.data.Total)
                setHappyAtCurrentPosition(res.data.HappyAtCurrentPosition)
                setPositionResponsibilities(res.data.PositionResponsibilities)
                setPaidFairly(res.data.PaidFairly)
                setHappyAtEmployer(res.data.HappyAtEmployer)
                setLookingForWork(res.data.LookingForWork)
                setRecommendEmployer(res.data.RecommendEmployer)
                setValuedByEmployer(res.data.ValuedByEmployer)
            })
    }, [])

    return (
        <div className='card m-5'>
            <div className='text-center mt-3'>
                <img src={logo} width={100} />
                <h1 className='text-center fw-light m-3'>Survey statistics </h1>
            </div>

            <div className='text-center' style={{ display: showLoader ? '' : 'none' }}>
                < ImSpinner4 size={100} className='text-dark mb-4 spinner' />
                <h4 className='text-black fw-light'>Loading...</h4>
            </div>
            <div style={{ display: showBody ? '' : 'none' }}>
                <h3 className='fw-light mb-5 text-center'>Total surveys completed {Total}</h3>
                <div className='row text-center m-5'>
                    <div className="col-sm-12 mb-2">
                        <h6 className={HappyAtCurrentPosition > 50 ? 'text-success fw-light' : 'text-danger fw-light'}> Happy employees at their current position {HappyAtCurrentPosition}%</h6>
                    </div>
                    <div className='col-sm-12 mb-2'>
                        <h6 className={PositionResponsibilities > 50 ? 'text-success fw-light' : 'text-danger fw-light'}> Employees that feel the responsibilities are fair {PositionResponsibilities}%</h6>
                    </div>
                    <div className='col-sm-12 mb-2'>
                        <h6 className={PaidFairly > 50 ? 'text-success fw-light' : 'text-danger fw-light'}> Employees that feel they are paid fairly {PaidFairly}%</h6>
                    </div>
                    <div className='col-sm-12 mb-2'>
                        <h6 className={HappyAtEmployer > 50 ? 'text-success fw-light' : 'text-danger fw-light'}> Employees that are happy at Legends {HappyAtEmployer}%</h6>
                    </div>
                    <div className='col-sm-12 mb-2'>
                        <h6 className={LookingForWork < 50 ? 'text-success fw-light' : 'text-danger fw-light'}> Employees that are looking for work {LookingForWork}%</h6>
                    </div>
                    <div className='col-sm-12 mb-2'>
                        <h6 className={RecommendEmployer > 50 ? 'text-success fw-light' : 'text-danger fw-light'}> Employees that would recommend Legends {RecommendEmployer}%</h6>
                    </div>
                    <div className='col-sm-12 mb-2'>
                        <h6 className={ValuedByEmployer > 50 ? 'text-success fw-light' : 'text-danger fw-light'}> Employees that feel valued {ValuedByEmployer}%</h6>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Stats
